.nav-link {
  font-family: "Baloo 2";
  font-style: normal;
  line-height: 26px;
  color: #18333e;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 17px;
  position: relative;
  transition: 0.3s;
  padding: 19px;
  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 0px;
    height: 63px;
    right: 300px;
    top: 50%;
    border-radius: 0px 31.5px 31.5px 0px;
    transform: translateY(-50%);
    background-color: #f9d44a;
    z-index: -1;
    transition: 0.3s;
  }
  &--active {
    gap: 43px;
    &::after {
      right: calc(100% - 70px);
      width: 130px;
    }
  }
}
