.messenger {
  height: 100%;
  display: flex;
  padding: 25px;
  gap: 5px;
  &__window {
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex: 1;
    .user-message {
      display: flex;
      gap: 16px;
      width: 100%;
      &--recipient {
        flex-direction: row-reverse;
      }
      &__data {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
      &__head {
        display: flex;
        gap: 16px;
      }
      &__full-name {
        color: #fff;
      }
      &__time {
        font-weight: 700;
        font-size: 11px;
        color: #595966;
      }
      &__value {
        padding: 14px 16px;
        border-radius: 0px 15px 15px 15px;
        background-color: rgb(40 43 51);
        color: #fff;
        font-size: 12px;
      }
    }
  }
  &__form {
    display: flex;
    align-items: end;
    flex: 1;
  }
  &__messages {
    position: relative;
    background-color: #333;
    border-radius: 6px;
    flex: 1;
    overflow-y: scroll;
  }
  &__input-wrapper {
    width: 100%;
    padding: 10px 16px;
    border-radius: 10px;
    display: flex;
    align-items: end;
    gap: 16px;
    background-color: rgb(23 26 30);
  }
  &__input {
    width: 100%;
    outline: none;
    border: none;
    background: transparent;
    font-size: 13px;
    align-self: center;
    color: #fff;
    max-height: 220px;
    overflow: auto;
    ::placeholder {
      color: rgb(89 89 102);
    }
  }
}
